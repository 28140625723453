import React, { useState, useEffect } from 'react'
import Menubar from '../Menu'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { FaMoon, FaSignOutAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import {
	Dropdown,
	Avatar,
	Space,
	Breadcrumb,
	Typography,
	Tooltip,
	Button,
	Drawer,
} from 'antd'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import { main } from '../../store/slices/main'
import { GlobalOutlined, CloseOutlined } from '@ant-design/icons'
import { Menu } from 'antd'

const Layout = ({ children }) => {
	const { getMe, subTitle, title } = useSelector((state) => state.main)
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const { setTheme } = main.actions
	const navigate = useNavigate()
	const [clicked, setClicked] = useState(false)
	const [theme, setTheme2] = useState('light')
	const [drawerVisible, setDrawerVisible] = useState(false)

	const [isScrolled, setIsScrolled] = useState(false)

	const handleScroll = () => {
		const scrollPosition = window.scrollY
		setIsScrolled(scrollPosition > 0)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		const savedTheme = localStorage.getItem('theme')
		if (savedTheme) {
			setTheme2(savedTheme)
			document.documentElement.classList.toggle('dark', savedTheme === 'dark')
		} else {
			const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
			const systemTheme = mediaQuery.matches ? 'dark' : 'light'
			setTheme2(systemTheme)
			document.documentElement.classList.toggle('dark', systemTheme === 'dark')
		}
	}, [])

	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
		const handleChange = (event) => {
			const newTheme = event.matches ? 'dark' : 'light'
			setTheme2(newTheme)
			document.documentElement.classList.toggle('dark', newTheme === 'dark')
			localStorage.setItem('theme', newTheme) // Update localStorage to reflect the change
			dispatch(setTheme(newTheme))
		}

		mediaQuery.addEventListener('change', handleChange)
		return () => mediaQuery.removeEventListener('change', handleChange)
	}, [])

	const toggleTheme = () => {
		const currentTheme = localStorage.getItem('theme') || 'light'
		const newTheme = currentTheme === 'dark' ? 'light' : 'dark'

		setTheme(newTheme)
		setTheme2(newTheme)
		dispatch(setTheme(newTheme))
		document.documentElement.classList.toggle('dark', newTheme === 'dark')
		localStorage.setItem('theme', newTheme)
	}

	useEffect(() => {
		const storedLanguage = localStorage.getItem('language')
		if (storedLanguage) {
			i18n.changeLanguage(storedLanguage)
		}
	}, [i18n])

	const handleClick = () => {
		navigate('/login')
	}

	const handleLanguageChange = (e) => {
		i18n.changeLanguage(e.key)
		localStorage.setItem('language', e.key)
	}
	const menu = (
		<Menu onClick={handleLanguageChange}>
			<Menu.Item key="ru">
				<Flag code="RU" style={{ width: 20, marginRight: 8 }} /> Russian
			</Menu.Item>
			<Menu.Item key="uzb">
				<Flag code="UZ" style={{ width: 20, marginRight: 8 }} /> Uzbek
			</Menu.Item>
		</Menu>
	)

	return (
		<div className="bg-background flex ">
			<Menubar theme={theme} />
			<div
				className="h-screen w-full overflow-y-auto bg-background"
				onClick={() => {
					if (clicked) setClicked(false)
				}}
			>
				<div
					className={`relative flex h-20 w-full items-center justify-between px-8 shadow-lg transition-all duration-300 ${isScrolled ? 'bg-inherit' : 'bg-white dark:bg-[#111111]'}`}
				>
					{' '}
					<div>
						{/* <Breadcrumb>
            <Breadcrumb.Item className="text-gray-500 dark:text-gray-300">{t("screen")}</Breadcrumb.Item>
            <Breadcrumb.Item className="text-gray-900 dark:text-white">{t(`${title}`)}</Breadcrumb.Item>
          </Breadcrumb> */}
						<Typography.Title
							level={3}
							className="!m-0 text-gray-900 dark:text-white"
						>
							{t(`${title}`)}
						</Typography.Title>
					</div>
					<Space
						size="large"
						className="bg-white dark:bg-gray-700 px-4 py-2 rounded-full shadow-md"
					>
						<Dropdown overlay={menu} trigger={['click']}>
							{/* <Tooltip title="Change Language"> */}
							<Button
								type="text"
								icon={
									<GlobalOutlined className="text-xl text-gray-600 dark:text-gray-300" />
								}
								shape="circle"
							/>
							{/* </Tooltip> */}
						</Dropdown>

						<Tooltip
							title={
								theme === 'dark'
									? 'Switch to Light Mode'
									: 'Switch to Dark Mode'
							}
						>
							<Button
								type="text"
								icon={
									<FaMoon className="text-xl text-gray-600 dark:text-gray-300" />
								}
								shape="circle"
								onClick={toggleTheme}
							/>
						</Tooltip>

						<Avatar
							className="bg-blue-500 dark:bg-blue-700 cursor-pointer"
							onClick={() => setDrawerVisible(true)}
						>
							{getMe?.FirstName ? getMe?.FirstName.charAt(0) : 'AP'}
						</Avatar>
					</Space>
				</div>

				<Drawer
					title={
						<div className="text-xl font-semibold dark:text-teal-50 text-black">
							{`${get(getMe, 'FirstName', '')} ${get(getMe, 'LastName', '')}`}
						</div>
					}
					placement="right"
					onClose={() => setDrawerVisible(false)}
					visible={drawerVisible}
					width={300}
					className="dark:bg-gray-800"
					closeIcon={
						<Button
							type="text"
							icon={<CloseOutlined />}
							onClick={() => setDrawerVisible(false)}
							className="text-black dark:text-white"
						/>
					}
				>
					<div className="space-y-2">
						{[
							{
								label: t('dollarCash'),
								value: get(getMe, 'U_CashAccount', ''),
							},
							{
								label: t('dollarCard'),
								value: get(getMe, 'U_CardUsdAccount', ''),
							},
							{ label: t('card'), value: get(getMe, 'U_CardAccount', '') },
							{
								label: t('SumCash'),
								value: get(getMe, 'U_CashUzsAccount', ''),
							},
							{
								label: t('terminal'),
								value: get(getMe, 'U_TerminalAccount', ''),
							},
							{
								label: t('transferCash'),
								value: get(getMe, 'U_TransAccount', ''),
							},
							{ label: t('whs'), value: get(getMe, 'U_Warehouse', '') },
							{ label: t('jobTitle'), value: get(getMe, 'JobTitle', '') },
						].map((item, index) => (
							<div key={index} className="flex justify-between">
								<Typography.Text type="secondary" className="dark:text-teal-50">
									{item.label}
								</Typography.Text>
								<Typography.Text className="dark:text-teal-50">
									{item.value}
								</Typography.Text>
							</div>
						))}
					</div>
					<Button
						type="primary"
						danger
						icon={<FaSignOutAlt />}
						className="mt-4 w-full"
						onClick={handleClick}
					>
						{t('logout')}
					</Button>
				</Drawer>

				{children}
			</div>
		</div>
	)
}

export default Layout
